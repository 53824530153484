import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ASSESSMENT_ANSWERS, ASSESSMENT_RESULTS } from '@ids-constants';
import { PER_PAGE } from '@microsec/constants';
import { BaseComponent } from '@ids-components';
import { CommonTableComponent } from '@microsec/components';
import { CommonToolbarConfiguration, CommonToolbarResult } from '@microsec/models';
import { TargetDeviceService } from '@ids-services';

import { MenuItem } from 'primeng/api';
import { BehaviorSubject, finalize } from 'rxjs';
import { ArrayToPlaceholderStringPipe } from '@ids-pipes';

@Component({
  selector: 'app-assessment-devices',
  templateUrl: './assessment-devices.component.html',
  styleUrls: ['./assessment-devices.component.scss'],
  providers: [ArrayToPlaceholderStringPipe],
})
export class AssessmentDevicesComponent extends BaseComponent implements OnInit, OnDestroy {
  isLoading = false;

  globalFilterFields: string[] = ['response', 'label', 'type', 'zones', 'result'];

  _selectedDevice = null;

  get selectedDevice() {
    return this._selectedDevice;
  }

  @Input() set selectedDevice(value: any) {
    this._selectedDevice = value;
    this.setDisplayDetails();
    this.selectedDeviceChange.emit(value);
  }

  @Output() selectedDeviceChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() mode: 'preview' | 'report_question' | 'report_device' | null = null;

  @Input() toolbarRightTemplate: TemplateRef<any> | null = null;

  filterConfiguration: CommonToolbarConfiguration = {
    types: ['search'],
    searchPlaceholder: this.arrayToPlaceholderStringPipe.transform(this.globalFilterFields),
  };

  @Input() isAllDevicesInit = false;

  _allDevices: any[] = [];

  get allDevices() {
    return this._allDevices;
  }

  @Input() set allDevices(value: any[]) {
    this._allDevices = value;
    this.setValues();
  }

  _devices: any[] = [];

  get devices() {
    return this._devices;
  }

  @Input() set devices(value: any[]) {
    this._devices = value;
    this.devicesChange?.emit(value);
    this.usePrefilling = !!(this.devices || []).filter((d) => d.is_prefilled).length;
    this.setValues();
  }

  @Output() devicesChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  _answers: any = {};

  get answers() {
    return this._answers;
  }

  @Input() set answers(value: any) {
    this._answers = value;
    this.onAnswerChange(false);
  }

  @Output() answersChange: EventEmitter<any> = new EventEmitter<any>();

  @Output() saveAnswerEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output() checkValidityEvent: EventEmitter<any> = new EventEmitter<any>();

  @Input() confidenceScore: any = null;

  deviceAnswers: any = {
    true: [],
    false: [],
    not_applicable: [],
    null: [],
  };

  filterResponse = '';

  values: any[] = [];

  cols: any[] = [];

  filters: {
    [key: string]: any;
  } = {};

  responseMenuOptions: MenuItem[] = [
    {
      label: 'Yes',
      items: [
        {
          label: 'Apply to Visible',
          command: () => this.setAnswers(ASSESSMENT_ANSWERS.TRUE),
        },
        {
          label: 'Apply to All',
          command: () => this.setAnswers(ASSESSMENT_ANSWERS.TRUE, true),
        },
      ],
    },
    {
      label: 'No',
      items: [
        {
          label: 'Apply to Visible',
          command: () => this.setAnswers(ASSESSMENT_ANSWERS.FALSE),
        },
        {
          label: 'Apply to All',
          command: () => this.setAnswers(ASSESSMENT_ANSWERS.FALSE, true),
        },
      ],
    },
    {
      label: 'N/A',
      items: [
        {
          label: 'Apply to Visible',
          command: () => this.setAnswers(ASSESSMENT_ANSWERS.NOT_APPLICABLE),
        },
        {
          label: 'Apply to All',
          command: () => this.setAnswers(ASSESSMENT_ANSWERS.NOT_APPLICABLE, true),
        },
      ],
    },
    {
      label: 'Reset',
      items: [
        {
          label: 'Apply to Visible',
          command: () => this.setAnswers(null),
        },
        {
          label: 'Apply to All',
          command: () => this.setAnswers(null, true),
        },
      ],
    },
  ];

  prefillingMenuItems: MenuItem[] = [
    {
      label: 'Confirm All',
      command: () => {
        this.values.forEach((d) => {
          this.onOptionClick(d, null);
        });
        this.onAnswerChange();
      },
    },
    {
      label: 'Undo All',
      command: () => {
        this.values.forEach((d) => {
          this.onOptionClick(d, null, true);
        });
        this.onAnswerChange();
      },
    },
  ];

  ASSESSMENT_RESULTS = ASSESSMENT_RESULTS;

  @ViewChild('dt') dt!: CommonTableComponent;

  filterSearch = '';

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  responseOptions = [
    { value: ASSESSMENT_ANSWERS.TRUE, label: 'Yes' },
    { value: ASSESSMENT_ANSWERS.FALSE, label: 'No' },
    { value: ASSESSMENT_ANSWERS.NOT_APPLICABLE, label: 'N/A' },
  ];

  displayDeviceDetails = false;

  usePrefilling = false;

  constructor(
    private targetDeviceSrv: TargetDeviceService,
    private arrayToPlaceholderStringPipe: ArrayToPlaceholderStringPipe,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.setCols();
    if (!this.isAllDevicesInit) {
      this.getDevices();
    }
    this.filterObjectObs.subscribe((values) => {
      if (!!values) {
        if (values?.isSortReset && this.dt?.datatable) {
          this.dt.datatable.sortField = null;
          this.dt.datatable.sortOrder = 1;
          this.dt.datatable.multiSortMeta = null;
          this.dt?.datatable.tableService.onSort(null);
        }
        if (this.filterSearch !== values.search) {
          this.dt?.datatable?.filterGlobal(values.search || '', 'contains');
        }
        this.filterSearch = values?.search || '';
      }
    });
  }

  getDevices() {
    this.isLoading = true;
    this.targetDeviceSrv
      .getDevices({
        organizationId: this.breadcrumbConfig?.organizationId,
        projectId: this.breadcrumbConfig?.projectId,
        detailed: false,
      })
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          this.allDevices = res?.devices;
          this.setValues();
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  setValues() {
    if (!!this.filterResponse && !this.deviceAnswers[this.filterResponse].length) {
      this.filterResponse = '';
    }
    this.values = (this.devices || [])
      ?.map((device) => ({
        ...(!!this.allDevices?.length ? this.allDevices?.find((d) => d.id === device.device_id) || {} : {}),
        ...device,
        id: device.device_id,
      }))
      .filter((device) => (!!this.filterResponse ? this.deviceAnswers[this.filterResponse]?.includes(device.id) : true));
  }

  filterByResponse(response: string) {
    this.filterResponse = this.filterResponse !== response ? response || '' : '';
    this.setValues();
  }

  setAnswers(answer: any, isAll = false) {
    const deviceIds = !isAll
      ? this.dt?.datatable?.value
          ?.slice(this.dt?.datatable?.first || 0, (this.dt?.datatable?.first || 0) + (this.dt?.datatable?.rows || PER_PAGE))
          ?.map((v) => v.id)
      : this.dt?.datatable?.value?.map((v) => v.id);
    (deviceIds || []).forEach((deviceId) => {
      this.answers[deviceId] = answer;
    });
    this.onAnswerChange();
  }

  onAnswerChange(isSave = true) {
    this.answersChange?.emit(this.answers);
    this.devicesChange?.emit(this.devices);
    this.checkValidityEvent?.emit();
    if (!!isSave) {
      this.saveAnswerEvent?.emit();
    }
    this.mappedAnswers();
    this.setValues();
  }

  onOptionClick(device: any, value: any, isUndo = false, changeAnswer = true) {
    if (!!device.is_prefilled && !device.user_confirmed) {
      this.answers[device.id] = value === null ? device.prefilled_answer : value;
    } else {
      const currentDeviceAnswer = this.answers[device.id];
      if (currentDeviceAnswer === value) {
        this.answers[device.id] = null;
      } else {
        this.answers[device.id] = value === null ? device.prefilled_answer : value;
      }
    }
    const deviceIndex = this.devices.findIndex((d) => d.device_id === device.id);
    if (!!this.devices[deviceIndex]) {
      this.devices[deviceIndex].user_confirmed = !isUndo;
      this.devices[deviceIndex].user_answer = this.answers[device.id];
    }
    if (!!changeAnswer) {
      this.onAnswerChange();
    }
  }

  mappedAnswers() {
    const deviceAnswers: any = {
      true: [],
      false: [],
      not_applicable: [],
      null: [],
    };
    Object.keys(this.answers || {}).forEach((deviceId: any) => {
      deviceAnswers[this.answers[deviceId]?.toString()]?.push(parseInt(deviceId));
    });
    this.deviceAnswers = deviceAnswers;
  }

  setDisplayDetails() {
    this.displayDeviceDetails = this.mode === 'preview' && !!this.selectedDevice;
  }

  setCols() {
    this.cols = [
      { field: 'label', header: 'Name', width: 15 },
      { field: 'type', header: 'Type', width: 15 },
      { field: 'zones', header: 'Zones', width: 15 },
      ...(this.mode === 'preview' ? [{ field: 'response', header: 'Response', width: 15 }] : []),
      ...(this.mode === 'report_question' || this.mode === 'report_device' ? [{ field: 'result', header: 'Compliance', width: 15 }] : []),
      ...(this.mode === 'report_question' || this.mode === 'report_device'
        ? [{ field: 'confidenceScore', header: 'Confidence Score', width: 15 }]
        : []),
    ];
    this.globalFilterFields = [...this.cols.map((col) => col.field)];
  }
}
