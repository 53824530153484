<microsec-base-app-root [customTopNavButtons]="customTopNavButtons" #baseApp />

<ng-template let-menu="menu" #researchCenterMenuStartTemplate>
  <div class="flex align-items-center justify-content-between gap-3">
    <span class="text-xl font-bold">Resource Center</span>
    <span class="cursor-pointer" pTooltip="Close" tooltipPosition="right" appendTo="body" (click)="menu.hide()">
      <i class="pi pi-times"></i>
    </span>
  </div>
</ng-template>

<ng-template let-item="item" #researchCenterMenuItemTemplate>
  <a pRipple class="flex align-items-center p-menuitem-link" [ngClass]="{ 'justify-content-end': !!item['isDismissAction'] }">
    @if (!!item.icon) {
      @if (item.icon !== 'custom-circle' && item.icon !== 'cve-search-icon') {
        <div class="surface-800 w-2rem h-2rem border-round-md flex align-items-center justify-content-center mr-3">
          <i [class]="'text-xl fa fa-' + item.icon"></i>
        </div>
      }
      @if (item.icon === 'custom-circle') {
        <span class="custom-circle mr-3" [ngClass]="{ checked: item.disabled }">
          @if (item.disabled) {
            <i class="fas fa-check text-xl"></i>
          }
        </span>
      }
      @if (item.icon === 'cve-search-icon') {
        <div class="surface-800 w-2rem h-2rem border-round-md flex align-items-center justify-content-center mr-3">
          <img class="h-1rem" src="/assets/images/cve-search-icon.svg" alt="cve-search-icon" />
        </div>
      }
    }
    <span [ngClass]="{ 'text-grey': !!item['isDismissAction'], 'line-through': item.disabled }">{{ item.label }}</span>
    @if (!!item['isExternalAction']) {
      <span class="ml-auto">
        <i class="text fas fa-arrow-up-right-from-square"></i>
      </span>
    }
  </a>
</ng-template>
