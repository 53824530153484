import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { API } from '@ids-services';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { IThreatService } from './IThreatService';
import { PROJECT_MANAGEMENT_CONSTANTS, THREAT_STATUSES } from '@ids-constants';
import { Store } from '@ngrx/store';
import { fromLayoutActions } from '@microsec/ngrx-layout';

const API_THREAT = `${API.THREAT_MANAGER}/threats`;
const API_THREAT_GROUP = `${API.THREAT_MANAGER}/threat_groups`;
const API_MITIGATION = `${API.THREAT_MANAGER}/mitigations`;

@Injectable({
  providedIn: 'root',
})
export class ThreatService implements IThreatService {
  refresh$: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  refreshObs: Observable<any> = this.refresh$.asObservable();

  selected$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  selectedObs: Observable<any> = this.selected$.asObservable();

  constructor(
    private httpClient: HttpClient,
    private store: Store,
  ) {}

  /** ******************************************************************************
   * ****************************** THREATS ******************************
   ******************************************************************************** */

  createThreat(payload: any): Observable<any> {
    return this.httpClient.post<any>(`${API_THREAT}/`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getThreats(
    project_id?: any,
    device_ids?: any[],
    page?: number,
    per_page?: number,
    sort = 'threat_id',
    reverse = true,
    detailed?: boolean,
    status?: string[],
    threat_type?: string[],
    search?: string,
    connection_id?: number[],
    is_imported?: boolean,
    attack_type?: string[],
    threat_score?: string[],
    created_from?: string,
    created_to?: string,
    updated_from?: string,
    updated_to?: string,
  ): Observable<any> {
    const params: string[] = [];
    if (project_id) {
      params.push(`project_id=${project_id}`);
    }
    if (page) {
      params.push(`page=${page}`);
    }
    if (per_page) {
      params.push(`per_page=${per_page}`);
    }
    if (!!sort) {
      params.push(`sort=${sort}`);
    }
    if (typeof reverse === 'boolean') {
      params.push(`reverse=${reverse}`);
    }
    if (typeof detailed === 'boolean') {
      params.push(`detailed=${detailed}`);
    }
    if (search) {
      params.push(`search=${search}`);
    }
    if (!!connection_id?.length) {
      params.push(`connection_id=${connection_id.join(',')}`);
    }
    if (typeof is_imported === 'boolean') {
      params.push(`is_imported=${is_imported}`);
    }
    if (!!status?.length) {
      params.push(`status=${status.join(',')}`);
    }
    if (!!threat_type?.length) {
      params.push(`threat_type=${threat_type.join(',')}`);
    }
    if (!!attack_type?.length) {
      params.push(`attack_type=${attack_type.join(',')}`);
    }
    if (!!device_ids?.length) {
      params.push(`device_ids=${device_ids.join(',')}`);
    }
    if (!!threat_score?.length) {
      params.push(`threat_score=${threat_score.join(',')}`);
    }
    if (created_from) {
      params.push(`created_from=${created_from}`);
    }
    if (created_to) {
      params.push(`created_to=${created_to}`);
    }
    if (updated_from) {
      params.push(`updated_from=${updated_from}`);
    }
    if (updated_to) {
      params.push(`updated_to=${updated_to}`);
    }
    return this.httpClient
      .get(`${API_THREAT}${!!params.length ? `?${params.join('&')}` : ''}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getThreat(id: number): Observable<any> {
    return this.httpClient.get<any>(`${API_THREAT}/${id}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  updateThreat(id: number, payload: any): Observable<any> {
    return this.httpClient.patch<any>(`${API_THREAT}/${id}`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  deleteThreat(id: number): Observable<any> {
    return this.httpClient.delete<any>(`${API_THREAT}/${id}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getAggregateThreatScore(): Observable<any> {
    return this.httpClient.get<any>(`${API_THREAT}/score`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getThreatSummary(
    organization_id?: any,
    project_id?: any,
    status?: string[],
    threat_type?: string[],
    attack_type?: string[],
    threat_score?: string[],
    from_date?: string,
    to_date?: string,
    range?: string,
  ): Observable<any> {
    const params: string[] = [];
    if (organization_id) {
      params.push(`organization_id=${organization_id}`);
    }
    if (project_id) {
      params.push(`project_id=${project_id}`);
    }
    if (!!status?.length) {
      params.push(`status=${status.map((s) => s.toLowerCase()).join(',')}`);
    }
    if (!!threat_type?.length) {
      params.push(`threat_type=${threat_type.map((s) => s.toLowerCase()).join(',')}`);
    }
    if (!!attack_type?.length) {
      params.push(`attack_type=${attack_type.map((s) => s.toLowerCase()).join(',')}`);
    }
    if (!!threat_score?.length) {
      params.push(`threat_score=${threat_score.map((s) => s.toLowerCase()).join(',')}`);
    }
    if (!!from_date) {
      params.push(`from_date=${from_date}`);
    }
    if (!!to_date) {
      params.push(`to_date=${to_date}`);
    }
    if (!!range) {
      params.push(`range=${range}`);
    }
    return this.httpClient
      .get<any>(`${API_THREAT}/summary${!!params.length ? `?${params.join('&')}` : ''}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  setThreatsCounter(organizationId: any, projectId: any): void {
    if (projectId) {
      const isGrouping = JSON.parse(localStorage.getItem('isThreatGroup') || 'true');
      const request: Observable<any> = !!isGrouping
        ? this.getThreatGroups(organizationId, projectId, 1, 1, undefined, undefined, [THREAT_STATUSES.OPEN, THREAT_STATUSES.FIXING])
        : this.getThreats(projectId, undefined, undefined, undefined, undefined, undefined, false, [THREAT_STATUSES.OPEN, THREAT_STATUSES.FIXING]);
      request.pipe().subscribe({
        next: (res) => {
          this.store.dispatch(new fromLayoutActions.AddLeftNavbarBadge(PROJECT_MANAGEMENT_CONSTANTS.THREATS.ROUTE, res?.total_record));
        },
      });
    }
  }

  /** ******************************************************************************
   * ****************************** MITIGATIONS ******************************
   ******************************************************************************** */

  createThreatMitigation(threat_id: number, payload: any): Observable<any> {
    return this.httpClient
      .post<any>(`${API_THREAT}/${threat_id}/mitigations`, payload)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getThreatMitigation(threat_id: number): Observable<any> {
    return this.httpClient.get<any>(`${API_THREAT}/${threat_id}/mitigations`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  updateMitigation(id: any, payload: any): Observable<any> {
    return this.httpClient.patch<any>(`${API_MITIGATION}/${id}`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  deleteMitigation(id: any): Observable<any> {
    return this.httpClient.delete<any>(`${API_MITIGATION}/${id}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  /** ******************************************************************************
   * ****************************** SETTINGS ******************************
   ******************************************************************************** */

  getThreatSetting(project_id: any): Observable<any> {
    return this.httpClient
      .get<any>(`${API.THREAT_MANAGER}/project/${project_id}/settings`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  updateThreatSetting(project_id: any, payload: any): Observable<any> {
    return this.httpClient
      .patch<any>(`${API.THREAT_MANAGER}/project/${project_id}/settings`, payload)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getThreatGroups(
    org_id?: any,
    project_id?: any,
    page?: number,
    per_page?: number,
    sort = 'threat_id',
    reverse = true,
    status?: string[],
    search?: string,
    connection_id?: number[],
    attack_type?: string[],
    threat_score?: string[],
    created_from?: string,
    created_to?: string,
    updated_from?: string,
    updated_to?: string,
  ): Observable<any> {
    const params: string[] = [];
    if (org_id) {
      params.push(`org_id=${org_id}`);
    }
    if (project_id) {
      params.push(`project_id=${project_id}`);
    }
    if (page) {
      params.push(`page=${page}`);
    }
    if (per_page) {
      params.push(`per_page=${per_page}`);
    }
    if (!!sort) {
      params.push(`sort=${sort}`);
    }
    if (typeof reverse === 'boolean') {
      params.push(`reverse=${reverse}`);
    }
    if (!!status?.length) {
      params.push(`status=${status.join(',')}`);
    }
    if (search) {
      params.push(`search=${search}`);
    }
    if (!!connection_id?.length) {
      params.push(`connection_id=${connection_id.join(',')}`);
    }
    if (!!attack_type?.length) {
      params.push(`attack_type=${attack_type.join(',')}`);
    }
    if (!!threat_score?.length) {
      params.push(`threat_score=${threat_score.join(',')}`);
    }
    if (created_from) {
      params.push(`created_from=${created_from}`);
    }
    if (created_to) {
      params.push(`created_to=${created_to}`);
    }
    if (updated_from) {
      params.push(`updated_from=${updated_from}`);
    }
    if (updated_to) {
      params.push(`updated_to=${updated_to}`);
    }
    return this.httpClient
      .get(`${API_THREAT_GROUP}${!!params.length ? `?${params.join('&')}` : ''}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getThreatGroup(id: number): Observable<any> {
    return this.httpClient.get<any>(`${API_THREAT_GROUP}/${id}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  updateThreatGroup(id: number, payload: any): Observable<any> {
    return this.httpClient.patch<any>(`${API_THREAT_GROUP}/${id}`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  deleteThreatGroup(id: number): Observable<any> {
    return this.httpClient.delete<any>(`${API_THREAT_GROUP}/${id}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getThreatGroupDevices(id: number): Observable<any> {
    return this.httpClient.get<any>(`${API_THREAT_GROUP}/${id}/devices`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }
}
