import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DELETE_LABEL, PER_PAGE, UPDATE_LABEL } from '@microsec/constants';
import {
  ATTACK_TYPE_OPTIONS,
  THREAT_SCORE_OPTIONS,
  THREAT_STATUSES,
  THREAT_STATUS_OPTIONS,
  THREAT_STATUS_UPDATES,
  THREAT_TYPE_OPTIONS,
} from '@ids-constants';
import { BaseComponent } from '@ids-components';
import { TargetDeviceService, ThreatService } from '@ids-services';
import { verticalSlideAnimation } from '@microsec/animations';
import { TitleCasePipe } from '@angular/common';
import { ConstantPipe, MomentPipe } from '@ids-pipes';
import { CommonTableComponent } from '@microsec/components';
import { ReportSpecification, CommonToolbarConfiguration, CommonToolbarResult, ActionMenuItem } from '@microsec/models';
import { ConnectionService } from '@ids-services';

import { BehaviorSubject, Observable, finalize, forkJoin } from 'rxjs';
import { LazyLoadEvent, MenuItem } from 'primeng/api';
import moment from 'moment';
import { OverlayPanel } from 'primeng/overlaypanel';

const FIELDS = {
  updated: 'Updated Date',
  created: 'Created Date',
  threat_id: 'ID',
  description: 'Description',
  detected_on: 'Detected on',
  comment: 'Comment',
  status: 'Status',
  device_ids: 'Impacted Devices',
  threat_type: 'Threat Type',
  attack_type: 'Attack Type',
  non_compliance_category: 'Category',
  attack_technique: 'Attack Technique',
  threat_accuracy: 'Threat Accuracy Score',
  threat_impact: 'Threat Impact Score',
  threat_likelihood: 'Threat Likelihood Score',
  threat_score: 'Threat Score',
  mitigations: 'Mitigations',
  cves: 'Related CVEs',
  is_true_positive: 'Threat Verification',
  vulnerability_package_name: 'Package Name',
  vulnerability_package_version: 'Package Version',
  vulnerability_port: 'Affected Port',
  vulnerability_service: 'Affected Service',
  mitre_technique_id: 'Mitre Technique ID',
  org_id: 'Organization ID',
  project_id: 'Project ID',
  snort_rule_id: 'Snort Rule ID',
  analyzer_id: 'Analyzer ID',
  connection_id: 'Connection ID',
  detailsComparators: 'Comparators',
  detailsPayload: 'Payload',
  detailsAffectedFeatures: 'Affected Features',
  is_imported: 'Imported',
};

const COLS = {
  DEFAULT: [
    { field: 'created', header: FIELDS.created, width: 12 },
    { field: 'threat_id', header: FIELDS.threat_id, width: 10 },
    { field: 'attack_type', header: FIELDS.attack_type, width: 12 },
    { field: 'threat_score', header: FIELDS.threat_score, width: 15 },
    { field: 'status', header: FIELDS.status, width: 15 },
    { field: 'updated', header: FIELDS.updated, width: 12 },
  ],
  DEVICE: [
    { field: 'created', header: FIELDS.created, width: 12 },
    { field: 'threat_type', header: FIELDS.threat_type, width: 12 },
    { field: 'threat_score', header: FIELDS.threat_score, width: 15 },
    { field: 'status', header: FIELDS.status, width: 15 },
    { field: 'updated', header: FIELDS.updated, width: 12 },
  ],
  VULNERABILITY: [
    { field: 'created', header: FIELDS.created, width: 12 },
    { field: 'threat_id', header: FIELDS.threat_id, width: 10 },
    { field: 'attack_type', header: FIELDS.attack_type, width: 12 },
    { field: 'device_ids', header: FIELDS.device_ids, width: 12 },
    { field: 'threat_score', header: FIELDS.threat_score, width: 15 },
    { field: 'status', header: FIELDS.status, width: 15 },
    { field: 'updated', header: FIELDS.updated, width: 12 },
  ],
  NON_COMPLIANCE: [
    { field: 'created', header: FIELDS.created, width: 12 },
    { field: 'non_compliance_category', header: FIELDS.non_compliance_category, width: 12 },
    { field: 'device_ids', header: FIELDS.device_ids, width: 12 },
    { field: 'status', header: FIELDS.status, width: 15 },
    { field: 'updated', header: FIELDS.updated, width: 12 },
  ],
};

const FILTER_CONFIGURATION: CommonToolbarConfiguration = {
  types: ['search', 'filter'],
  searchPlaceholder: 'Search Threat ID, Snort Rule ID, MITRE Technique ID, CVE ID, Payload Details, Comment or Description...',
  filters: {
    1: {
      key: 'attackTypes',
      label: 'Attack Type',
      type: 'multiselect',
      options: ATTACK_TYPE_OPTIONS,
    },
    2: {
      key: 'connections',
      label: 'Connection',
      type: 'multiselect',
      options: [],
    },
    3: {
      key: 'devices',
      label: 'Device',
      type: 'multiselect',
      options: [],
    },
    4: {
      key: 'createdDates',
      label: 'Created Date',
      type: 'date-range',
    },
    5: {
      key: 'isImported',
      label: 'Import Status',
      type: 'dropdown',
      options: [
        { label: 'Imported', value: true },
        { label: 'Not Imported', value: false },
      ],
    },
    6: {
      key: 'statuses',
      label: 'Status',
      defaultValue: [THREAT_STATUSES.OPEN, THREAT_STATUSES.FIXING],
      type: 'multiselect',
      options: THREAT_STATUS_OPTIONS,
    },
    7: {
      key: 'threatScores',
      label: 'Threat Score',
      type: 'multiselect',
      options: THREAT_SCORE_OPTIONS,
    },
    8: {
      key: 'threatTypes',
      label: 'Threat Type',
      type: 'multiselect',
      options: THREAT_TYPE_OPTIONS,
    },
    9: {
      key: 'updatedDates',
      label: 'Updated Date',
      type: 'date-range',
    },
  },
};

const ITEM_NAME_BY_MODE = {
  ['ANOMALY']: 'Anomaly',
  ['MALWARE']: 'Malware',
  ['VULNERABILITY']: 'Vulnerability',
  ['NON_COMPLIANCE']: 'Non-Compliance',
  ['DEVICE']: 'Threats',
  ['GROUP']: 'Threats',
};

@Component({
  selector: 'app-shared-threats',
  templateUrl: './shared-threats.component.html',
  styleUrls: ['./shared-threats.component.scss'],
  providers: [ConstantPipe, MomentPipe, TitleCasePipe],
  animations: [verticalSlideAnimation],
})
export class SharedThreatsComponent extends BaseComponent implements OnInit, OnDestroy {
  isLoading = false;

  configPrepared = false;

  _mode: 'ANOMALY' | 'MALWARE' | 'VULNERABILITY' | 'NON_COMPLIANCE' | 'DEVICE' | 'GROUP' | null = null;

  get mode() {
    return this._mode;
  }

  @Input() set mode(values: 'ANOMALY' | 'MALWARE' | 'VULNERABILITY' | 'NON_COMPLIANCE' | 'DEVICE' | 'GROUP' | null) {
    this._mode = values;
    this.cols = values ? (COLS as any)?.[values] || COLS.DEFAULT : COLS.DEFAULT;
    this.selectedColFields = [
      'created',
      ...(this.mode !== 'DEVICE' && this.mode !== 'NON_COMPLIANCE' ? ['threat_id', 'attack_type'] : []),
      ...(this.mode === 'DEVICE' ? ['threat_type'] : []),
      ...(this.mode === 'NON_COMPLIANCE' ? ['non_compliance_category'] : []),
      ...(this.mode === 'VULNERABILITY' || this.mode === 'NON_COMPLIANCE' ? ['device_ids'] : []),
      'threat_score',
      'status',
    ];
    if (!!this.configPrepared && !!this.mode && this.mode !== 'DEVICE' && this.mode !== 'GROUP') {
      this.getThreats();
    }
  }

  filterConfiguration: CommonToolbarConfiguration | null = null;

  getThreatsInterval: any;

  rowStatusButtonItems: MenuItem[] = [];

  statusButtonItems: MenuItem[] = [];

  statusAllButtonItems: MenuItem[] = [];

  _device: any = null;

  get device() {
    return this._device;
  }

  @Input() set device(values: any) {
    this._device = values;
    this.activeTotalRecords = 0;
    this.selectedThreats = [];
    this.selectedThreat = null;
    if (!!this.configPrepared && !!this.device?.id) {
      this.getThreats();
      this.getActiveTotalRecords();
    }
  }

  currentPage = 1;

  totalRecords = 0;

  _activeTotalRecords = 0;

  get activeTotalRecords() {
    return this._activeTotalRecords;
  }

  @Input() set activeTotalRecords(value: any) {
    this._activeTotalRecords = value;
    this.activeTotalRecordsChange.emit(this._activeTotalRecords);
  }

  @Output() activeTotalRecordsChange: EventEmitter<any> = new EventEmitter<any>();

  _values: any[] = [];

  get values() {
    return this._values;
  }

  @Input() set values(value: any[]) {
    this._values = ((value as any[]) || []).map((v) => ({
      ...v,
      updatableStatus: v.status,
    }));
    this.setUpdateStatusButtonItems();
  }

  cols: any[] = [];

  selectedCols: any[] = [];

  _selectedColFields: string[] = [];

  get selectedColFields(): string[] {
    return this._selectedColFields;
  }

  set selectedColFields(value: string[]) {
    this._selectedColFields = value;
    this.selectedCols = (!!this.cols?.length ? this.cols : COLS.DEFAULT).filter((col) => value?.includes(col.field));
  }

  selectedThreats: any[] = [];

  _selectedThreat = null;

  get selectedThreat() {
    return this._selectedThreat;
  }

  set selectedThreat(value: any) {
    this._selectedThreat = value;
    this.displayThreatDetails = !!value && (this.mode === 'DEVICE' || this.mode === 'GROUP');
  }

  displayThreatDetails = false;

  filters: {
    [key: string]: any;
  } = {};

  PER_PAGE = PER_PAGE;

  THREAT_STATUS_OPTIONS = THREAT_STATUS_OPTIONS;

  @ViewChild('dt') dt!: CommonTableComponent;

  @ViewChild('rowStatusEditPanel') rowStatusEditPanel!: OverlayPanel;

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  actionsMenuItems: ActionMenuItem[] = [];

  ITEM_NAME_BY_MODE = ITEM_NAME_BY_MODE;

  constructor(
    private constantPipe: ConstantPipe,
    private momentPipe: MomentPipe,
    private titleCasePipe: TitleCasePipe,
    public threatSrv: ThreatService,
    private targetDeviceSrv: TargetDeviceService,
    private connectionSrv: ConnectionService,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.configPrepared = true;
    this.actionsMenuItems = [
      {
        label: 'Change Status',
        icon: 'fa fa-list-check',
        visible: () => !!this.permissions[this.SCOPE.PROJECT][this.USER_ROLE.ADMIN],
        items: THREAT_STATUS_OPTIONS.map((status) => ({
          label: `${THREAT_STATUS_UPDATES[status.value]}`,
          disabled: ({ rowData }) => rowData.status === status.value,
          command: ({ rowData }) => this.openUpdateStatusConfirmation(status.value, [rowData], rowData.status),
        })),
      },
      {
        label: 'Delete',
        icon: 'fas fa-trash',
        visible: () => !!this.permissions[this.SCOPE.PROJECT][this.USER_ROLE.ADMIN],
        command: ({ rowData }) => this.openDeleteConfirmation([rowData]),
      },
    ];
    this.filters['statuses'] = FILTER_CONFIGURATION?.filters?.[6].defaultValue || [];
    if (this.mode !== 'DEVICE') {
      this.getDevices();
    }
    if (this.mode !== 'GROUP') {
      this.getThreats();
    }
    if (this.mode === 'DEVICE') {
      this.getActiveTotalRecords();
    }
    this.getConnections();
    this.initFilterConfiguration();
    this.getThreatsInterval = setInterval(() => {
      if (this.mode !== 'GROUP') {
        this.getThreats(false, false);
      }
      if (this.mode === 'DEVICE') {
        this.getActiveTotalRecords();
      }
    }, 60000);
    this.subscriptions.forEach((s) => s.unsubscribe());
    const subscription = this.threatSrv.refreshObs.subscribe((rs) => {
      if (!!rs) {
        if (this.mode !== 'GROUP') {
          this.getThreats(rs.resetSelectedThreats);
        }
        if (this.mode === 'DEVICE') {
          this.getActiveTotalRecords();
        }
      }
    });
    this.subscriptions.push(subscription);
  }

  initFilterConfiguration() {
    const filterConfiguration: CommonToolbarConfiguration = this.util.cloneDeepObject(FILTER_CONFIGURATION);
    if (this.mode !== 'DEVICE') {
      delete filterConfiguration.filters?.[8];
    } else {
      delete filterConfiguration.filters?.[2];
    }
    this.filterConfiguration = filterConfiguration;
    this.filterObjectObs.subscribe((values) => {
      if (!!values) {
        this.assignFilters(!!values?.isFiltered ? values : {});
        if (!!values?.isFiltered) {
          this.currentPage = 1;
          if (this.dt?.datatable) {
            this.dt.datatable.first = 0;
          }
        }
        if (values?.isSortReset && this.dt?.datatable) {
          this.dt.datatable.sortField = null;
          this.dt.datatable.sortOrder = 1;
          this.dt.datatable.multiSortMeta = null;
          this.dt?.datatable.tableService.onSort(null);
        }
        this.getThreats();
      }
    });
  }

  assignFilters(value: any) {
    this.filters = {
      ...(value?.filter || {}),
      search: value?.search || null,
      createdFrom: value?.filter?.createdDates?.from || null,
      createdTo: value?.filter?.createdDates?.to || null,
      updatedFrom: value?.filter?.updatedDates?.from || null,
      updatedTo: value?.filter?.updatedDates?.to || null,
    };
  }

  getThreatsRequest(page?: number, perPage?: number, sortField?: string, sortOrder?: number) {
    const request: Observable<any> = this.threatSrv.getThreats(
      this.breadcrumbConfig?.projectId,
      this.mode === 'DEVICE' && !!this.device.id ? [Number(this.device.id)] : this.filters?.['devices'],
      page,
      perPage,
      sortField === 'connection_id' ? 'connection' : sortField,
      sortOrder ? sortOrder === 1 : true,
      true,
      this.filters?.['statuses'],
      this.mode === 'DEVICE' ? this.filters?.['threatTypes'] : [this.mode?.toLowerCase()],
      this.filters?.['search'],
      this.filters?.['connections'],
      this.filters?.['isImported'],
      this.filters?.['attackTypes'],
      !!this.filters?.['threatScores']?.length
        ? THREAT_SCORE_OPTIONS.filter((item) => ((this.filters?.['threatScores'] as any[]) || []).find((filter) => filter === item.value)).map(
            (param) => param.value,
          )
        : this.filters?.['threatScores'],
      this.filters?.['createdFrom'] ? (moment(this.filters?.['createdFrom']).toISOString() as any) : null,
      this.filters?.['createdTo'] ? (moment(this.filters?.['createdTo']).toISOString() as any) : null,
      this.filters?.['updatedFrom'] ? (moment(this.filters?.['updatedFrom']).toISOString() as any) : null,
      this.filters?.['updatedTo'] ? (moment(this.filters?.['updatedTo']).toISOString() as any) : null,
    );
    return request;
  }

  getThreats(resetSelectedThreats = true, showLoading = true, event?: LazyLoadEvent) {
    if (showLoading) {
      this.isLoading = true;
    }
    const page = !event ? this.currentPage : Math.floor((event as any)?.first / (event?.rows as number)) + 1;
    const perPage = event?.rows || this.dt?.datatable?.rows || PER_PAGE;
    this.getThreatsRequest(page, perPage, this.dt?.datatable?.sortField as any, this.dt?.datatable?.sortOrder)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          this.currentPage = res?.page;
          this.totalRecords = res?.total_record;
          const threats = (res?.threats as any[]) || [];
          this.values = threats;
          if (!!this.selectedThreat) {
            this.selectedThreat = threats.find((threat) => threat.threat_id === this.selectedThreat.threat_id) || null;
          }
          if (!!resetSelectedThreats) {
            this.selectedThreats = [];
          }
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  getActiveTotalRecords() {
    this.threatSrv
      .getThreats(
        this.breadcrumbConfig?.projectId,
        (this.mode === 'DEVICE' && !!this.device.id ? [Number(this.device.id)] : null) as any,
        undefined,
        undefined,
        undefined,
        undefined,
        false,
        [THREAT_STATUSES.OPEN, THREAT_STATUSES.FIXING],
      )
      .pipe()
      .subscribe({
        next: (res) => {
          this.activeTotalRecords = res?.total_record;
        },
        error: () => {
          /** Nothing */
        },
      });
  }

  getConnections() {
    this.connectionSrv
      .getConnections(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId)
      .pipe()
      .subscribe({
        next: (res) => {
          const connectionOptions = (res.data as any[] | []).map((con: { name: any; id: any }) => ({
            label: con.name || con.id,
            value: con.id,
          }));
          if (this.filterConfiguration?.filters?.[2]) {
            this.filterConfiguration.filters[2].options = connectionOptions;
          }
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  getDevices() {
    this.targetDeviceSrv
      .getDevices({
        organizationId: this.breadcrumbConfig?.organizationId,
        projectId: this.breadcrumbConfig?.projectId,
        reverse: false,
        detailed: false,
      })
      .pipe()
      .subscribe({
        next: (res) => {
          const deviceOptions = ((res?.devices as any[]) || []).map((device) => ({
            label: device.label || device.id,
            value: device.id,
          }));
          if (this.filterConfiguration?.filters?.[3]) {
            this.filterConfiguration.filters[3].options = deviceOptions;
          }
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  openGenerateReportDialog() {
    const onGenerateReport = (specification: ReportSpecification) => {
      if (!!specification) {
        const filename = `${
          this.mode === 'DEVICE' ? `device_${this.device.id}` : `project_${this.breadcrumbConfig?.projectId}_${this.mode?.toLowerCase()}`
        }_threats`;
        if (specification.pageOption === 'current') {
          this.dt.generateReportDialog.exportReport(this.getMappedReportData(this.values), filename);
        } else {
          const requests: Observable<any>[] =
            specification.pageOption === 'custom'
              ? this.util
                  .getSequenceInteger(specification.customPages.from as any, specification.customPages.to as any)
                  .map((page) => this.getThreatsRequest(page, this.dt?.datatable?.rows || PER_PAGE))
              : [this.getThreatsRequest(1, this.mode !== 'GROUP' ? this.totalRecords : this.values.length)];
          forkJoin(requests).subscribe({
            next: (res) => {
              const data: any[] = [];
              res.forEach((threats) => {
                data.push(...(threats?.threats || []));
              });
              this.dt.generateReportDialog.exportReport(this.getMappedReportData(data), filename);
            },
            error: () => {
              this.showErrorMessage('Error while getting all threats data');
              this.dt.generateReportDialog.onClose();
            },
          });
        }
      }
    };
    this.dt.generateReportDialog.open(onGenerateReport, FIELDS, this.selectedCols, []);
  }

  getMappedReportData(data: any[]) {
    return (data || []).map((threat) => ({
      ...threat,
      device_ids: !!threat?.device_ids?.length
        ? ((threat?.device_ids as any[]) || []).map((device) => device.label || `Device ${device.device_id}`).join(', ')
        : null,
      created: this.momentPipe?.transform(threat.created),
      updated: this.momentPipe?.transform(threat.updated),
      status: this.titleCasePipe?.transform(threat.status),
      attack_type: this.constantPipe?.transform(threat.attack_type, 'threat-attack-type'),
      non_compliance_category: threat.non_compliance_category?.toUpperCase(),
      detailsComparators: threat?.details?.comparators,
      detailsPayload: threat?.details?.payload,
      detailsAffectedFeatures: !!threat?.details?.affected_features?.length ? threat.details.affected_features.join(', ') : null,
    }));
  }

  updateStatusThreats(status: any, threats: any[]) {
    this.isLoading = true;
    const payload = {
      status: status,
    };
    const requests: Observable<any>[] = threats.map((threat) => this.threatSrv.updateThreat(threat.threat_id, payload));
    forkJoin(requests)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.showSuccessMessage(`${status === THREAT_STATUSES.ARCHIVED ? 'Archived' : 'Updated status'} threat(s) successfully`);
          this.threatSrv.refresh$.next(true);
        },
        error: (err) => {
          this.showErrorMessage(err);
        },
      });
  }

  deleteThreats(threats: any[]) {
    this.isLoading = true;
    const requests: Observable<any>[] = threats.map((threat) => this.threatSrv.deleteThreat(threat.threat_id));
    forkJoin(requests)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.showSuccessMessage(`Deleted threat(s) successfully`);
          if (!!this.selectedThreats?.length) {
            this.selectedThreats = [];
          }
          if (!!this.selectedThreat) {
            const threatIds = threats.map((threat) => threat.threat_id) || [];
            this.selectedThreat = !!threatIds.includes(this.selectedThreat.threat_id) ? null : this.selectedThreat;
          }
          setTimeout(() => {
            this.threatSrv.refresh$.next(true);
          });
        },
        error: (err) => {
          this.showErrorMessage(err);
        },
      });
  }

  openDeleteConfirmation(threats: any[] = []) {
    const threatList = threats.map((threat) => `<li>${`Threat ID: ${threat.threat_id}`}</li>`);
    this.confirm({
      action: DELETE_LABEL,
      objectName: 'Threat(s)',
      customContent: `Are you sure you want to delete ${
        !threats?.length ? 'all threats?<br/><br/>' : `the following threat(s)?<ul>${threatList.join('')}</ul>`
      }`,
      next: () => {
        if (!threats?.length) {
          this.getThreatsRequest(1, this.mode !== 'GROUP' ? this.totalRecords : this.values.length)
            .pipe()
            .subscribe({
              next: (res) => {
                const allThreats = (res?.threats as any[]) || [];
                this.deleteThreats(allThreats);
              },
              error: () => {
                this.showErrorMessage('Error while getting all threats data');
                this.isLoading = false;
              },
            });
        } else {
          this.deleteThreats(threats);
        }
      },
    });
  }

  openUpdateStatusConfirmation(status: string, threats: any[] = [], oldStatus?: string) {
    const toStatusStr = ` to ${status.toLowerCase()}`;
    const threatList = threats.map((threat) => `<li>${`Threat ID: ${threat.threat_id}`}</li>`);
    this.confirm({
      action: UPDATE_LABEL,
      objectName: 'Threat(s)',
      customContent: `Are you sure you want to update ${
        !threats?.length ? `all threats${toStatusStr}?<br/><br/>` : `the following threat(s)${toStatusStr}?<ul>${threatList.join('')}</ul>`
      }`,
      next: () => {
        if (!threats?.length) {
          this.getThreatsRequest(1, this.totalRecords)
            .pipe()
            .subscribe({
              next: (res) => {
                const allThreats = (res?.threats as any[]) || [];
                this.updateStatusThreats(status === `Unarchived` ? 'Open' : status, allThreats);
              },
              error: () => {
                this.showErrorMessage('Error while getting all threats data');
                this.isLoading = false;
              },
            });
        } else {
          this.updateStatusThreats(status, threats);
        }
      },
      rejectCallback: () => {
        if (!!oldStatus && threats[0].updatableStatus) {
          threats[0].updatableStatus = oldStatus;
        }
      },
    });
  }

  onClickEditStatusButton(event: any, rowData: any) {
    const rowStatusButtonItems: MenuItem[] = THREAT_STATUS_OPTIONS.map((status) => ({
      label: `${THREAT_STATUS_UPDATES[status.value]}`,
      disabled: rowData.status === status.value,
      command: () => this.openUpdateStatusConfirmation(status.value, [rowData], rowData.status),
    }));
    this.rowStatusButtonItems = rowStatusButtonItems;

    this.rowStatusEditPanel.toggle(event);
  }

  setUpdateStatusButtonItems() {
    const statusAllButtonItems: any[] = [];
    const statusButtonItems: any[] = [];
    THREAT_STATUS_OPTIONS.forEach((status) => {
      statusAllButtonItems.push({
        label: `${THREAT_STATUS_UPDATES[status.value]} All`,
        command: () => this.openUpdateStatusConfirmation(status.value),
        disabled: !this.values?.length,
      });
      statusButtonItems.push({
        label: `${THREAT_STATUS_UPDATES[status.value]} Selected`,
        command: () => this.openUpdateStatusConfirmation(status.value, this.selectedThreats),
      });
    });
    this.statusAllButtonItems = statusAllButtonItems;
    this.statusButtonItems = statusButtonItems;
  }

  hideDetailsPanel(evt?: any) {
    if (!!evt && (evt.target.localName !== 'div' || (evt.target.localName === 'div' && evt.target.id !== 'tb'))) {
      return;
    }
    this.selectedThreat = null;
  }

  override ngOnDestroy(): void {
    this.cleanup();
    clearInterval(this.getThreatsInterval);
    this.threatSrv.selected$.next(null);
  }
}
