<microsec-loading-overlay [isLoading]="!!isLoading">
  <div class="flex align-items-center justify-content-between gap-2 mb-3">
    <div class="flex align-items-center gap-2">
      <p-button
        icon="fa fa-filter"
        label="Filter"
        [rounded]="true"
        [styleClass]="
          !!$any(filters)?.[FILTER_PARAMS.ASSESSMENT_TYPE_ID]?.length ||
          !!$any(filters)?.[FILTER_PARAMS.TAG_ID]?.length ||
          !!$any(filters)?.[FILTER_PARAMS.DEVICE_ID]?.length
            ? 'text-transform-none'
            : 'toolbar-button'
        "
        [disabled]="!totalAssessments"
        (onClick)="openFiltersModal()"></p-button>
      <p-button
        icon="fa fa-filter-circle-xmark"
        label="Clear"
        [rounded]="true"
        styleClass="toolbar-button"
        [disabled]="!totalAssessments"
        (onClick)="resetFilters()"></p-button>
    </div>
    <div>
      <microsec-refresh-button (refreshEvent)="!totalAssessments ? getData() : getAssessmentSummary()" />
    </div>
  </div>
  <div class="grid mb-2">
    <div class="col-12 sm:col-6 lg:col-4 xl:col">
      <ng-container
        [ngTemplateOutlet]="widgetTemplate"
        [ngTemplateOutletContext]="{
          widget: {
            valueClass: 'text-highlight',
            value: (summary?.tags_assessed || 0) + '/' + (summary?.scope?.tags?.length || 0),
            label: 'Tag(s) Assessed',
            key: 'tags',
            data: 'tags'
          }
        }"></ng-container>
    </div>
    <div class="col-12 sm:col-6 lg:col-4 xl:col">
      <ng-container
        [ngTemplateOutlet]="widgetTemplate"
        [ngTemplateOutletContext]="{
          widget: {
            valueClass: 'text-highlight',
            value: (summary?.devices_assessed || 0) + '/' + (summary?.scope?.devices?.length || 0),
            label: 'Device(s) Assessed',
            key: 'devices',
            data: 'devices'
          }
        }"></ng-container>
    </div>
    <div class="col-12 sm:col-6 lg:col-4 xl:col">
      <ng-container
        [ngTemplateOutlet]="widgetTemplate"
        [ngTemplateOutletContext]="{
          widget: {
            valueClass: 'text-red',
            value: summary?.non_compliance_threats?.length || 0,
            label: 'Threat(s)',
            key: 'threats',
            data: 'threats'
          }
        }"></ng-container>
    </div>
    <div class="col-12 sm:col-6 lg:col-4 xl:col">
      <ng-container
        [ngTemplateOutlet]="widgetTemplate"
        [ngTemplateOutletContext]="{
          widget: {
            valueClass: 'text-red',
            value: summary?.non_compliance_mitigations?.length || 0,
            label: 'Open Mitigation(s)',
            key: 'mitigations',
            data: 'mitigations'
          }
        }"></ng-container>
    </div>
    <div class="col-12 sm:col-6 lg:col-4 xl:col">
      <ng-container
        [ngTemplateOutlet]="widgetTemplate"
        [ngTemplateOutletContext]="{
          widget: {
            valueClass: 'text-highlight',
            value: summary?.scope?.assessment_types?.length || 0,
            label: 'Assessment Type(s)',
            key: 'assessment_types',
            data: 'assessment_types'
          }
        }"></ng-container>
    </div>
    <div class="col-12 sm:col-6 lg:col-4 xl:col">
      <ng-container
        [ngTemplateOutlet]="widgetTemplate"
        [ngTemplateOutletContext]="{
          widget: {
            valueClass: 'text-highlight',
            value: summary?.scope?.assessments?.length || 0,
            label: 'Assessment(s)',
            key: 'assessments',
            data: 'assessments'
          }
        }"></ng-container>
    </div>
    <div [@fadeAnimation] *ngIf="!!tables[1].visible" class="col-12">
      <ng-container
        [ngTemplateOutlet]="detailTemplate"
        [ngTemplateOutletContext]="{
          index: 1
        }"></ng-container>
    </div>
  </div>
  <div class="grid mb-2">
    <div class="col-12 lg:col-6 xl:col-4">
      <microsec-card [isBordered]="true" [isFull]="true">
        <div class="font-bold mb-3">Compliance Scorecard</div>
        <div class="my-auto">
          <div class="flex align-items-baseline justify-content-center">
            @if (summary?.displayTotalScore === 0 || summary?.displayTotalScore > 0) {
              <span class="text-7xl font-bold">{{ summary.displayTotalScore }}</span>
              <span class="text-2xl font-bold">/100</span>
            } @else {
              <span class="text-7xl font-bold">—</span>
            }
          </div>
          <div class="p-2">
            <microsec-common-gauge-horizontal [thresholds]="gauge.thresholds" [pinColor]="gauge.pinColor" [value]="summary?.displayTotalScore || 0" />
          </div>
          <div class="p-3" *ngIf="!totalAssessments || summary?.total_score === undefined || summary?.total_score === null">
            Create an assessment to see your current cybersecurity posture score.
          </div>
          <div class="p-3" *ngIf="!!totalAssessments && summary?.total_score !== undefined && summary?.total_score !== null">
            According to MicroIDS's benchmark analysis, your current cybersecurity posture is rated
            <span
              [ngClass]="{
                'text-green': summary?.total_result === ASSESSMENT_RESULTS.COMPLIANT,
                'text-orange': summary?.total_result === ASSESSMENT_RESULTS.PARTIALLY_COMPLIANT,
                'text-red': summary?.total_result === ASSESSMENT_RESULTS.NOT_COMPLIANT,
                'text-grey': summary?.total_result === ASSESSMENT_RESULTS.UNANSWERED || summary?.total_result === ASSESSMENT_RESULTS.NOT_APPLICABLE
              }"
              >{{ summary?.displayTotalScore === 0 || summary?.displayTotalScore > 0 ? summary.displayTotalScore + '%' : '—' }}</span
            >. <span *ngIf="summary?.displayTotalScore === 100">Well done. It is recommended to periodically assess your cybersecurity posture.</span
            ><span *ngIf="summary?.displayTotalScore !== 100"
              ><span *ngIf="summary?.displayTotalScore > 0">However, there is still room for improvement.</span> To enhance security measures, refer
              to the results below for detailed information on non-compliant devices and their corresponding recommended remediation steps.</span
            >
          </div>
        </div>
      </microsec-card>
    </div>
    <div class="col-12 lg:col-6 xl:col-4">
      <ng-container
        [ngTemplateOutlet]="chartTemplate"
        [ngTemplateOutletContext]="{
          tableIndex: 2,
          key: CHART_KEYS.DEVICES_CRITICALITY_COMPLIANCE
        }"></ng-container>
    </div>
    <div class="col-12 lg:col-6 xl:col-4">
      <microsec-card [isBordered]="true" [isFull]="true">
        <div class="font-bold mb-3">Compliance Timeline</div>
        <div class="my-auto" [ngClass]="{ hidden: !summary?.historical?.length }">
          <div style="height: '20rem'">
            <canvas #timelineDiagram></canvas>
          </div>
          <div class="flex flex-row flex-wrap justify-content-center ml-2">
            <div class="py-2" *ngFor="let option of timelineChartOptions">
              <p-radioButton labelStyleClass="mr-6" [(ngModel)]="selectedTimelineChart" [value]="option.value" [label]="option.label"></p-radioButton>
            </div>
          </div>
        </div>
        @if (!summary?.historical?.length) {
          <ng-container [ngTemplateOutlet]="noDataTemplate"></ng-container>
        }
      </microsec-card>
    </div>
    <div [@fadeAnimation] *ngIf="!!tables[2].visible" class="col-12">
      <ng-container
        [ngTemplateOutlet]="detailTemplate"
        [ngTemplateOutletContext]="{
          index: 2
        }"></ng-container>
    </div>
  </div>
  <div class="grid mb-2">
    <div class="col-12 lg:col-6 xl:col-4">
      <ng-container
        [ngTemplateOutlet]="chartTemplate"
        [ngTemplateOutletContext]="{
          tableIndex: 3,
          key: CHART_KEYS.THREATS_BY_SCORE
        }"></ng-container>
    </div>
    <div class="col-12 lg:col-6 xl:col-4">
      <ng-container
        [ngTemplateOutlet]="chartTemplate"
        [ngTemplateOutletContext]="{
          tableIndex: 3,
          key: CHART_KEYS.THREATS_BY_NON_COMPLIANCE_CATEGORY
        }"></ng-container>
    </div>
    <div class="col-12 lg:col-6 xl:col-4">
      <ng-container
        [ngTemplateOutlet]="chartTemplate"
        [ngTemplateOutletContext]="{
          tableIndex: 3,
          key: CHART_KEYS.MITIGATIONS
        }"></ng-container>
    </div>
    <div *ngIf="!!tables[3].visible" class="col-12">
      <ng-container
        [ngTemplateOutlet]="detailTemplate"
        [ngTemplateOutletContext]="{
          index: 3
        }"></ng-container>
    </div>
  </div>
</microsec-loading-overlay>

<ng-template #widgetTemplate let-widget="widget">
  <microsec-card
    [isBordered]="true"
    [isFull]="true"
    class="cursor-pointer"
    [ngClass]="{ 'selected-widget': $any(tables[1])?.key === widget.key }"
    (click)="$any(tables[1])?.key !== widget.key ? openWidgetDetails(widget.data) : resetTableData(1)">
    <div class="flex align-items-center justify-content-between">
      <div class="flex flex-column gap-2">
        <span [class]="'font-bold text-xl ' + widget.valueClass">
          {{ widget.value }}
        </span>
        <span class="text-sm">{{ widget.label }}</span>
      </div>
      <span>
        <i class="pi pi-chevron-down toggle-chevron-icon" [ngClass]="{ 'chevron-up': $any(tables[1])?.key === widget.key }"></i>
      </span>
    </div>
  </microsec-card>
</ng-template>

<ng-template #chartTemplate let-tableIndex="tableIndex" let-key="key">
  <microsec-card
    [isChartCard]="true"
    [isBordered]="true"
    [isFull]="true"
    [ngClass]="{ 'selected-widget': $any(tables)[tableIndex]?.key === key }"
    (click)="resetTableData(tableIndex)">
    <div class="flex align-items-center gap-2 mb-3">
      <label class="font-bold">{{ $any(charts[key])?.label }}</label>
      <span *ngIf="!!chartsInfo[key]" class="flex align-items-center" [pTooltip]="chartsInfo[key]" tooltipPosition="right" appendTo="body">
        <i class="pi pi-info-circle"></i>
      </span>
    </div>
    @if (!!charts[key] && !!this.chartsData[key]?.length) {
      <div class="my-auto">
        <microsec-common-chart #diagram [displayTitle]="false" [chartConfig]="charts[key]" [legendPosition]="null" [isLoading]="isLoadingCharts" />
      </div>
    } @else {
      <ng-container [ngTemplateOutlet]="noDataTemplate"></ng-container>
    }
  </microsec-card>
</ng-template>

<ng-template #detailTemplate let-index="index">
  @if (!!$any(tables)[index]) {
    @if (!!$any(tables)[index].title) {
      <microsec-card [isBordered]="true">
        <div class="grid mb-3">
          <label class="col-12 font-bold text-xl">{{ $any(tables)[index].title }}</label>
          <label *ngIf="!!$any(tables)[index].description" class="col-12 text-grey">{{ $any(tables)[index].description }}</label>
        </div>
        <div>
          <ng-container [ngTemplateOutlet]="tableTemplate"> </ng-container>
        </div>
      </microsec-card>
    } @else {
      <ng-container [ngTemplateOutlet]="tableTemplate"> </ng-container>
    }

    <ng-template #tableTemplate>
      <microsec-card [isTableCard]="true">
        <microsec-common-table
          #dt
          [cols]="$any(tables)[index].cols || []"
          [values]="$any(tables)[index].values || []"
          [itemName]="$any(tables)[index].itemName || 'item'"
          [loading]="isLoading"
          [(selection)]="$any(tables)[index].selected"
          (selectionChange)="onTableSelected(index)"
          [cellTemplate]="cellTemplate"
          [containerHeaderRightTemplate]="containerHeaderRightTemplate" />

        <ng-template #containerHeaderRightTemplate>
          <div class="flex flex-wrap align-items-center gap-2">
            <p-button
              type="button"
              icon="pi pi-chevron-up"
              pTooltip="Close Details"
              tooltipPosition="bottom"
              appendTo="body"
              severity="secondary"
              (onClick)="resetTableData(index)">
            </p-button>
          </div>
        </ng-template>

        <ng-template let-col="col" let-rowData="rowData" #cellTemplate>
          <ng-container [ngSwitch]="col.field">
            <!-- Date -->
            <ng-container *ngSwitchCase="col.field === 'created' || col.field === 'updated' ? col.field : ''">
              {{ rowData[col.field] | momentPipe }}
            </ng-container>
            <!-- Category -->
            <ng-container *ngSwitchCase="'non_compliance_category'">
              {{ rowData[col.field] ? (rowData[col.field] | uppercase) : '-' }}
            </ng-container>
            <!-- Description -->
            <ng-container *ngSwitchCase="'description'">
              <div class="flex flex-wrap">
                {{ rowData[col.field] || '-' }}
              </div>
            </ng-container>
            <!-- Compliant Devices -->
            <ng-container *ngSwitchCase="'compliant_devices'">
              <label class="text-green">
                {{ rowData[col.field]?.length || 0 }}
              </label>
            </ng-container>
            <!-- Partially Compliant Devices -->
            <ng-container *ngSwitchCase="'partially_compliant_devices'">
              <label class="text-orange">
                {{ rowData[col.field]?.length || 0 }}
              </label>
            </ng-container>
            <!-- Not Compliant Devices -->
            <ng-container *ngSwitchCase="'not_compliant_devices'">
              <label class="text-red">
                {{ rowData[col.field]?.length || 0 }}
              </label>
            </ng-container>
            <!-- Not Assessed Devices -->
            <ng-container *ngSwitchCase="'not_assessed_devices'">
              <label class="text-grey">
                {{ rowData[col.field]?.length || 0 }}
              </label>
            </ng-container>
            <!-- Type -->
            <ng-container *ngSwitchCase="'type'">
              <microsec-truncate-text>
                {{ (rowData[col.field] | constant: 'detected-device-type') || '-' }}
              </microsec-truncate-text>
            </ng-container>
            <!-- Tags -->
            <ng-container *ngSwitchCase="'tags'">
              <div
                class="flex align-items-center w-max"
                [pTooltip]="!!rowData[col.field]?.length ? $any(rowData[col.field] | arrayMapPipe: 'label' : true) : ''"
                tooltipPosition="top"
                appendTo="body">
                <ng-container *ngFor="let tag of rowData[col.field] || []; let i = index; let firstTag = first; let lastTag = last">
                  <div *ngIf="!!firstTag" class="custom-chip" [style]="{ background: util.hexToRgba(tag.color), color: tag.color }">
                    <!-- (click)="onClickSelectedTag($event, tag)"> -->
                    {{ tag.label }}
                  </div>
                  <div *ngIf="!firstTag && !!lastTag" class="ml-1">+{{ i }}</div>
                </ng-container>
              </div>
            </ng-container>
            <!-- Compliance -->
            <ng-container
              *ngSwitchCase="col.field === 'overall_result' || col.field === 'result_overall' || col.field === 'total_result' ? col.field : ''">
              <span
                [ngClass]="{
                  'text-green': rowData[col.field] === ASSESSMENT_RESULTS.COMPLIANT,
                  'text-orange': rowData[col.field] === ASSESSMENT_RESULTS.PARTIALLY_COMPLIANT,
                  'text-red': rowData[col.field] === ASSESSMENT_RESULTS.NOT_COMPLIANT,
                  'text-grey': rowData[col.field] === ASSESSMENT_RESULTS.UNANSWERED || rowData[col.field] === ASSESSMENT_RESULTS.NOT_APPLICABLE
                }">
                {{ (rowData[col.field] | constant: 'assessment-result') || '-' }}
              </span>
            </ng-container>
            <!-- Compliance Score -->
            <ng-container *ngSwitchCase="col.field === 'overall_score' || col.field === 'total_score' ? col.field : ''">
              <span
                [ngClass]="{
                  'text-green': rowData[col.field] === 1,
                  'text-orange': rowData[col.field] > 0,
                  'text-red': rowData[col.field] === 0,
                  'text-grey': rowData[col.field] === null || rowData[col.field] === undefined || !rowData['is_assessed']
                }">
                {{
                  !!rowData['is_assessed'] && (rowData[col.field] === 0 || rowData[col.field] > 0)
                    ? (rowData[col.field] * 100 | number: '1.0-0') + '%'
                    : '-'
                }}
              </span>
            </ng-container>
            <!-- Assessment Compliance Score -->
            <ng-container *ngSwitchCase="'score_overall'">
              <span
                [ngClass]="{
                  'text-green': rowData[col.field] === 1,
                  'text-orange': rowData[col.field] > 0,
                  'text-red': rowData[col.field] === 0,
                  'text-grey': rowData[col.field] === null || rowData[col.field] === undefined
                }">
                {{ rowData[col.field] === 0 || rowData[col.field] > 0 ? (rowData[col.field] * 100 | number: '1.0-0') + '%' : '—' }}
              </span>
            </ng-container>
            <!-- Type -->
            <ng-container *ngSwitchCase="'assessment_type_id'">
              {{ rowData.assessmentType?.name || 'Assessment Type ID ' + rowData.assessment_type_id }}
            </ng-container>
            <!-- Status -->
            <ng-container *ngSwitchCase="'status'">
              <div
                class="uppercase max-w-max bordered-status"
                [ngClass]="{
                  red: rowData.status.toLowerCase() === 'open',
                  green: rowData.status.toLowerCase() === 'closed',
                  orange: rowData.status.toLowerCase() === 'fixing',
                  grey: rowData.status.toLowerCase() === 'archived'
                }">
                {{ rowData['status'] || '-' }}
              </div>
            </ng-container>
            <!-- Attack Type -->
            <ng-container *ngSwitchCase="'attack_type'">
              {{ rowData.attack_type ? (rowData.attack_type | constant: 'threat-attack-type') : '-' }}
            </ng-container>
            <!-- Threat Score -->
            <ng-container *ngSwitchCase="'threat_score'">
              <microsec-score-scale [inputNumber]="rowData[col.field]" [tooltipText]="'Threat Score'"></microsec-score-scale>
            </ng-container>
            <!-- Impacted Device(s) -->
            <ng-container *ngSwitchCase="'device_ids'">
              @if (!!rowData.devices?.length) {
                <ng-container *ngFor="let d of rowData.devices; let lastD = last">
                  {{ d.label || 'Device ' + d.id }}<span *ngIf="!lastD">,&nbsp;</span>
                </ng-container>
              }
            </ng-container>
            <!-- Color -->
            <ng-container *ngSwitchCase="'color'">
              <div class="h-2rem w-2rem border-round-xs" [style.background]="rowData[col.field]"></div>
            </ng-container>
            <!-- Action -->
            <ng-container *ngSwitchCase="'action'">
              <div class="w-max flex align-items-center gap-3" (click)="$event.stopPropagation()">
                <p-button
                  *ngIf="!!permissions[SCOPE.PROJECT][USER_ROLE.TECHNICIAN] || currentUser?.id === rowData.assignee_user_id"
                  icon="fas fa-clipboard-list"
                  label="View Result"
                  severity="success"
                  size="small"
                  [rounded]="true"
                  styleClass="text-transform-none custom-button w-9rem"
                  (onClick)="openAssessmentReport(rowData)">
                </p-button>
              </div>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <div class="overflow-hidden white-space-nowrap text-overflow-ellipsis">
                {{ rowData[col.field] || '-' }}
              </div>
            </ng-container>
          </ng-container>
        </ng-template>
      </microsec-card>
    </ng-template>
  }
</ng-template>

<ng-template #noDataTemplate>
  <div class="flex h-full" style="min-height: 15rem">
    <div class="m-auto">
      <label>No data found</label>
    </div>
  </div>
</ng-template>

<p-sidebar
  [(visible)]="tableSelection.devices.visible"
  [showCloseIcon]="false"
  [style]="{ width: '50rem' }"
  position="right"
  appendTo="body"
  [baseZIndex]="998"
  (onHide)="onTableUnselected()">
  @if (!!tableSelection.devices.id && !!tableSelection.devices.visible) {
    <app-detected-device-details [deviceId]="tableSelection.devices.id" (hidePanelEvent)="onTableUnselected()"></app-detected-device-details>
  }
</p-sidebar>

<p-sidebar
  [(visible)]="tableSelection.threats.visible"
  [showCloseIcon]="false"
  [style]="{ width: '50rem' }"
  position="right"
  appendTo="body"
  [baseZIndex]="998"
  (onHide)="onTableUnselected()">
  @if (!!tableSelection.threats.id && !!tableSelection.threats.visible) {
    <app-threat-details [threatId]="tableSelection.threats.id" (hidePanelEvent)="onTableUnselected()"></app-threat-details>
  }
</p-sidebar>
