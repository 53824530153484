import { Component, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { USER_ROLE } from '@microsec/constants';
import { BaseComponent } from '@ids-components';
import { ILayoutItem } from '@microsec/interfaces';

import { DASHBOARD_CHILDREN } from './dashboard.config';
import { fromLayoutActions } from '@microsec/ngrx-layout';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor() {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    if (!!this.checkPermissionsByScope(USER_ROLE.READ_ONLY, true)) {
      const tabItems: ILayoutItem[] = this.util.cloneObjectArray(DASHBOARD_CHILDREN).filter((p) => !!p.label);
      tabItems.forEach((item) => {
        if (!!item.featureName && !!item.featureCheckField) {
          item.visible = this.features?.[item.featureName]?.[item.featureCheckField];
        }
      });
      this.store.dispatch(new fromLayoutActions.UpdateTabItems(tabItems));
    }
  }

  ngAfterViewInit(): void {
    const openResourceCenter = JSON.parse(localStorage.getItem('openResourceCenter') || 'false');
    if (!!openResourceCenter) {
      setTimeout(() => {
        const elements = document.querySelectorAll('button.resource-center-icon');
        for (let i = 0; i < elements.length; i++) {
          const element = elements[i];
          if (element instanceof HTMLElement) {
            element.click();
          }
        }
        localStorage.removeItem('openResourceCenter');
      }, 1000);
    }
  }

  override ngOnDestroy() {
    this.cleanup({ shouldClearTabItems: true });
  }
}
