import { AfterViewInit, Component, Input, ViewChild, effect } from '@angular/core';
import { BaseComponent } from '@ids-components';
import { PER_PAGE } from '@microsec/constants';
import { CommonToolbarConfiguration, CommonToolbarResult } from '@microsec/models';
import { DEVICE_FILTER_CONFIG, THREAT_FILTER_CONFIG } from './nm-item-list.config';
import { BehaviorSubject, finalize, forkJoin } from 'rxjs';
import { NetworkMap } from '@ids-models';
import { CommonToolbarComponent } from '@microsec/components';
import { ConnectionService, TargetDeviceService, ThreatService } from '@ids-services';
import {
  ATTACK_TYPE_OPTIONS,
  INTERFACE_TYPE_LABELS,
  INTERFACE_TYPE_OPTIONS,
  NETWORK_MAP_SELECTION_TYPES,
  NETWORK_MAP_SETTING_KEYS,
  NETWORK_MAP_SETTING_VALUES,
  THREAT_SCORE_OPTIONS,
} from '@ids-constants';
import moment from 'moment';

@Component({
  selector: 'app-nm-item-list',
  templateUrl: './nm-item-list.component.html',
  styleUrls: ['./nm-item-list.component.scss'],
})
export class NmItemListComponent extends BaseComponent implements AfterViewInit {
  isLoading = false;

  _type = null;

  get type() {
    return this._type;
  }

  @Input() set type(value: any) {
    this._type = value;
    switch (value) {
      case NETWORK_MAP_SELECTION_TYPES.DEVICE: {
        this.filterConfiguration = this.util.cloneDeepObject(DEVICE_FILTER_CONFIG);
        break;
      }
      case NETWORK_MAP_SELECTION_TYPES.THREAT: {
        this.filterConfiguration = this.util.cloneDeepObject(THREAT_FILTER_CONFIG);
        break;
      }
      default: {
        break;
      }
    }
  }

  @Input() instance!: NetworkMap;

  links: any = {};

  connections: any[] = [];

  values: any[] = [];

  diagramDevices: any[] = [];

  isMulticast: 'any' | 'false' =
    localStorage.getItem(`network_map_${NETWORK_MAP_SETTING_VALUES.DEVICE.DEVICE_MULTICAST}`) === 'true' ? 'any' : 'false';

  filterConfiguration: CommonToolbarConfiguration | null = null;

  filterObject: CommonToolbarResult = {
    search: '',
    sort: null,
    filter: {},
    isFiltered: false,
    isSortReset: false,
  };

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  PER_PAGE = PER_PAGE;

  @ViewChild('dv') dataView!: DataView;

  @ViewChild('ct') toolbar!: CommonToolbarComponent;

  constructor(
    private targetDeviceSrv: TargetDeviceService,
    private connectionSrv: ConnectionService,
    private threatSrv: ThreatService,
  ) {
    super();
    effect(
      () => {
        const settings = this.instance.settings().items;
        const settingItems = (settings.find((p) => p.key === NETWORK_MAP_SETTING_KEYS.DEVICE).items as any[]) || [];
        const isMulticast = !!settingItems.find((p) => p.value === NETWORK_MAP_SETTING_VALUES.DEVICE.DEVICE_MULTICAST && !!p.checked)
          ? 'any'
          : 'false';
        if (this.isMulticast !== isMulticast) {
          this.isMulticast = isMulticast;
          this.getItems();
        }
      },
      { allowSignalWrites: true },
    );
    effect(
      () => {
        const isRefreshing = this.instance.isRefreshing();
        if (!isRefreshing) {
          this.filterItems(true);
        }
      },
      { allowSignalWrites: true },
    );
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.getItems();
    this.filterObjectObs.subscribe((result) => {
      if (result) {
        this.filterObject = this.util.cloneDeepObject(result);
        this.filterItems();
      }
    });
  }

  /**
   * Get devices/threats list
   */
  getItems() {
    switch (this.type) {
      case NETWORK_MAP_SELECTION_TYPES.DEVICE: {
        this.getDevices();
        break;
      }
      case NETWORK_MAP_SELECTION_TYPES.THREAT: {
        this.getThreats();
        break;
      }
      default: {
        break;
      }
    }
  }

  /**
   * Get devices
   */
  private getDevices() {
    this.isLoading = true;
    forkJoin({
      connectionData: this.connectionSrv.getConnections(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId),
      tagData: this.targetDeviceSrv.getTags(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId),
      zoneData: this.targetDeviceSrv.getZones(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId),
      deviceData: this.targetDeviceSrv.getDevices({
        organizationId: this.breadcrumbConfig?.organizationId,
        projectId: this.breadcrumbConfig?.projectId,
        detailed: false,
        isMulticast: this.isMulticast,
      }),
      linkData: this.targetDeviceSrv.getLinks(),
    })
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe((result) => {
        this.values = (result.deviceData?.devices as any[]) || [];
        this.diagramDevices = ((result.deviceData?.devices as any[]) || []).map((p) => ({
          ...p,
          mac_address: Object.keys(p?.eth || {})?.[0] || '',
          ip_address: p?.ip ? Object.keys(p.ip)?.[0] + (Object.keys(p.ip).length > 1 ? '...' : '') : '',
        }));
        const links: any = this.util.cloneDeepObject(result.linkData || {});
        Object.entries(links).forEach(([key, linkItems]: [string, any]) => {
          if (key !== 'default') {
            links[key] = (linkItems as any[]).map((p) => ({ ...p, communication_protocol: key }));
          }
        });
        this.links = links;
        this.connections = result.connectionData?.data || [];

        const connectionOptions = (result.connectionData?.data as any[] | []).map((con: { name: any; id: any }) => ({
          label: con.name || con.id,
          value: con.id,
        }));
        if (!!this.filterConfiguration) {
          this.filterConfiguration.totalRecords = result.deviceData?.total_record || 0;
          if (!!this.filterConfiguration.filters?.[2]) {
            this.filterConfiguration.filters[2].options = connectionOptions;
          }
          const tagOptions = (result.tagData?.tags as any[] | []).map((tag) => ({
            label: tag.label,
            value: tag.id,
          }));
          const zoneOptions = (result.zoneData?.zones as any[] | []).map((zone) => ({
            label: zone.label,
            value: zone.id,
          }));
          if (this.filterConfiguration.filters?.[8]) {
            this.filterConfiguration.filters[8].options = tagOptions;
          }
          if (this.filterConfiguration.filters?.[9]) {
            this.filterConfiguration.filters[9].options = zoneOptions;
          }
        }

        this.instance.getDeviceItems = this.getDevices.bind(this);
        this.instance.diagramData.update((diagramData) => {
          diagramData.shouldResetDiagram.set(true);
          diagramData.connections = this.connections;
          diagramData.links = this.getLinks();
          diagramData.devices = this.diagramDevices.map((device) => {
            device.children = [
              ...diagramData.links.filter((p) => p.src_device_id === device.id).map((p) => p.dest_device_id),
              ...diagramData.links.filter((p) => p.dest_device_id === device.id).map((p) => p.src_device_id),
            ];
            device.relative = diagramData.links.filter((p) => p.src_device_id === device.id || p.dest_device_id === device.id).length || 0;
            return device;
          });
          diagramData.legends.set(this.getLegendData(this.links));
          return diagramData;
        });
        this.instance.isFirstLoaded.set(true);
      });
  }

  /**
   * Get threats
   */
  private getThreats() {
    this.isLoading = true;
    forkJoin({
      connectionData: this.connectionSrv.getConnections(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId),
      deviceData: this.targetDeviceSrv.getDevices({
        organizationId: this.breadcrumbConfig?.organizationId,
        projectId: this.breadcrumbConfig?.projectId,
        reverse: false,
        detailed: false,
        isMulticast: this.isMulticast,
      }),
      threatData: this.threatSrv.getThreats(
        this.breadcrumbConfig?.projectId,
        this.filterObject.filter?.devices,
        undefined,
        undefined,
        this.filterObject.sort?.key,
        !!this.filterObject.sort?.direction ? this.filterObject.sort.direction === 'desc' : true,
        false,
        this.filterObject.filter?.statuses,
        this.filterObject.filter?.threatTypes,
        this.filterObject.search,
        this.filterObject.filter?.connections,
        this.filterObject.filter?.isImported,
        this.filterObject.filter?.attackTypes,
        !!this.filterObject.filter?.threatScores?.length
          ? THREAT_SCORE_OPTIONS.filter((item) => this.filterObject.filter?.threatScores.find((filter: any) => filter === item.value)).map(
              (param) => param.value,
            )
          : this.filterObject.filter?.threatScores,
        this.filterObject.filter?.createdDates?.from ? moment(this.filterObject.filter?.createdDates?.from).toISOString() || '' : undefined,
        this.filterObject.filter?.createdDates?.to ? moment(this.filterObject.filter?.createdDates?.to).toISOString() || '' : undefined,
        this.filterObject.filter?.updatedDates?.from ? moment(this.filterObject.filter?.updatedDates?.from).toISOString() || '' : undefined,
        this.filterObject.filter?.updatedDates?.to ? moment(this.filterObject.filter?.updatedDates?.to).toISOString() || '' : undefined,
      ),
    })
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          Object.entries(res).forEach(([key, value]: [string, any]) => {
            switch (key) {
              case 'threatData': {
                if (!!this.filterConfiguration) {
                  this.filterConfiguration.totalRecords = value?.total_record || 0;
                }
                const threats = ((value?.threats as any[]) || []).map((p) => ({
                  ...p,
                  attackTypeLabel: ATTACK_TYPE_OPTIONS.find((a) => a.value === p?.attack_type?.toLowerCase())?.label,
                }));
                this.values = threats;
                break;
              }
              case 'deviceData': {
                const deviceOptions = ((value?.devices as any[]) || []).map((device) => ({
                  label: device.label || device.id,
                  value: device.id,
                }));
                if (this.filterConfiguration?.filters?.[3]) {
                  this.filterConfiguration.filters[3].options = deviceOptions;
                }
                break;
              }
              case 'connectionData': {
                const connectionOptions = (value.data as any[] | []).map((con: { name: any; id: any }) => ({
                  label: con.name || con.id,
                  value: con.id,
                }));
                if (this.filterConfiguration?.filters?.[2]) {
                  this.filterConfiguration.filters[2].options = connectionOptions;
                }
                break;
              }
              default: {
                break;
              }
            }
          });
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  /**
   * Filter items
   * @param isRefreshed
   */
  filterItems(isRefreshed = false) {
    switch (this.type) {
      case NETWORK_MAP_SELECTION_TYPES.DEVICE: {
        this.filterDevices(isRefreshed);
        break;
      }
      case NETWORK_MAP_SELECTION_TYPES.THREAT: {
        this.getThreats();
        break;
      }
      default: {
        break;
      }
    }
  }

  /**
   * Filter devices
   * @param isRefreshed
   */
  private filterDevices(isRefreshed = false) {
    this.isLoading = true;
    this.targetDeviceSrv
      .getDevices({
        organizationId: this.breadcrumbConfig?.organizationId,
        projectId: this.breadcrumbConfig?.projectId,
        sort: this.filterObject.sort?.key,
        reverse: !!this.filterObject.sort?.direction ? this.filterObject.sort.direction === 'desc' : true,
        detailed: false,
        ...(this.filterObject.filter || {}),
        search: this.filterObject.search,
        connectionIds: this.filterObject.filter?.connections,
        interfaceTypes: this.filterObject.filter?.interfaces,
        tagIds: this.filterObject.filter?.tags,
        zoneIds: this.filterObject.filter?.zones,
        createdFrom: this.filterObject.filter?.createdDates?.from
          ? moment(this.filterObject.filter?.createdDates?.from).toISOString() || ''
          : undefined,
        createdTo: this.filterObject.filter?.createdDates?.to ? moment(this.filterObject.filter?.createdDates?.to).toISOString() || '' : undefined,
        lastSeenFrom: this.filterObject.filter?.lastSeenDates?.from
          ? moment(this.filterObject.filter?.lastSeenDates?.from).toISOString() || ''
          : undefined,
        lastSeenTo: this.filterObject.filter?.lastSeenDates?.to ? moment(this.filterObject.filter?.lastSeenDates?.to).toISOString() || '' : undefined,
        isMulticast: this.isMulticast,
      })
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          if (!!this.filterConfiguration) {
            this.filterConfiguration.totalRecords = res?.total_record || 0;
          }
          this.values = (res?.devices as any[]) || [];
          // Make search node check
          this.instance
            .diagramData()
            .searchedDevices.set(
              this.filterObject.search !== '' || !!this.filterObject.isFiltered
                ? this.diagramDevices?.filter((d) => !!this.values.find((p) => p.id === d.id)) || []
                : [],
            );
          if (!!isRefreshed) {
            this.instance.drawDiagram();
          }
        },
        error: (err) => {
          if (!!this.filterConfiguration) {
            this.filterConfiguration.totalRecords = 0;
          }
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Get links
   * @returns
   */
  getLinks() {
    const filteredLinks: any[] = [];
    Object.values(this.links || {}).forEach((links: any) => {
      if (!!Array.isArray(links)) {
        filteredLinks.push(...((links as any[]) || []));
      }
    });
    return filteredLinks;
  }

  /**
   * Get legends
   * @param linksObj
   * @returns
   */
  getLegendData(linksObj: any) {
    const legends: any[] = [];
    Object.keys(linksObj).forEach((key) => {
      if (key !== 'default') {
        const legend = {
          value: key,
          label: INTERFACE_TYPE_LABELS?.[key] || key,
          color: INTERFACE_TYPE_OPTIONS.find((p) => p.value === key)?.color || this.getDynamicColor(),
        };
        legends.push(legend);
      }
    });
    return legends;
  }

  /**
   * Generate dynamic colors
   * @returns
   */
  private getDynamicColor() {
    return '#' + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, '0');
  }
}
