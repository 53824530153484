import { Component, Input, OnInit, computed } from '@angular/core';
import { BaseComponent } from '@ids-components';
import {
  NETWORK_MAP_MODES,
  NETWORK_MAP_MODE_VALUES,
  NETWORK_MAP_SETTING_KEYS,
  NETWORK_MAP_SETTING_VALUES,
  PROJECT_MANAGEMENT_CONSTANTS,
} from '@ids-constants';
import { NetworkMap } from '@ids-models';
import { ORGANIZATION_LEVEL_ROUTE, PROJECT_LEVEL_ROUTE } from '@microsec/constants';
import { Util } from '@microsec/utilities';

@Component({
  selector: 'app-nmb-switch',
  templateUrl: './nmb-switch.component.html',
  styleUrls: ['./nmb-switch.component.scss'],
})
export class NmbSwitchComponent extends BaseComponent implements OnInit {
  @Input() instance!: NetworkMap;

  modes = this.util.cloneObjectArray(NETWORK_MAP_MODES);

  isSettingsDisplayed = false;

  displayMode =
    localStorage.getItem(`network_map_${NETWORK_MAP_SETTING_VALUES.DISPLAY_MODE.SIMPLE}`) === 'true'
      ? NETWORK_MAP_SETTING_VALUES.DISPLAY_MODE.SIMPLE
      : NETWORK_MAP_SETTING_VALUES.DISPLAY_MODE.DETAILED;

  NETWORK_MAP_SETTING_KEYS = NETWORK_MAP_SETTING_KEYS;

  NETWORK_MAP_MODE_VALUES = NETWORK_MAP_MODE_VALUES;

  ngOnInit() {
    // Add event listeners
    document.addEventListener('click', (event: any) => {
      const settingButtonElement = document.getElementById('network-map-settings-button');
      const menuElement = document.getElementById('network-map-settings-menu');
      if (!menuElement?.contains(event?.target) && !settingButtonElement?.contains(event?.target)) {
        this.isSettingsDisplayed = false;
      }
    });
  }

  async changeMode(mode: any) {
    await this.prepareConfigs();
    if (mode !== NETWORK_MAP_MODE_VALUES.EDITOR) {
      this.router.navigate([], { queryParams: { mode }, queryParamsHandling: 'merge' });
      this.instance.mode.set(mode);
    } else {
      this.changeRoute(
        `${ORGANIZATION_LEVEL_ROUTE}/${this.breadcrumbConfig?.organizationId}/` +
          `${PROJECT_LEVEL_ROUTE}/${this.breadcrumbConfig?.projectId}/` +
          `${PROJECT_MANAGEMENT_CONSTANTS.NETWORK_MAP_EDITOR.ROUTE}`,
      );
    }
  }

  /**
   * Change setting
   * @param settingKey
   * @param item
   * @param isChecked
   */
  changeSetting(settingKey: any, item: any, isChecked: boolean | null) {
    const settings = this.instance.settings();
    const setting = settings.items.find((p) => p.key === settingKey);
    if (settingKey === NETWORK_MAP_SETTING_KEYS.DISPLAY_MODE) {
      if (item.checked === isChecked) {
        return;
      }
      ((setting.items as any[]) || []).forEach((settingItem) => {
        settingItem.checked = settingItem.value === this.displayMode;
        localStorage.setItem(`network_map_${settingItem?.value}`, settingItem?.checked);
      });
      // Fix: Refresh to make sure the position changes of FD works
      setTimeout(() => {
        this.instance.refresh();
      });
    } else {
      item.checked = isChecked;
      localStorage.setItem(`network_map_${item?.value}`, item?.checked);
      const settingItem = ((setting.items as any[]) || [])?.find((p) => p.value === item.value);
      if (!!settingItem) {
        settingItem.checked = item.checked;
      }
    }
    this.instance.settings.set(Util.cloneDeepObject(settings));
  }

  /**
   * Update legend data
   */
  legends = computed(() => {
    return this.instance.diagramData()?.legends() || [];
  });

  /**
   * Check if `Show legend` is selected
   */
  isLegendChecked = computed(() => {
    const settingItems = this.instance.settings().items;
    return settingItems.find((setting) => !!((setting?.items as any[]) || []).find((item) => item.value === 'legend' && !!item.checked));
  });
}
