<div class="grid">
  <div class="col-12">
    <span class="text-2xl">Device Overview</span>
  </div>
  <div class="col-12" *ngIf="!selectedDevice">
    <app-assessment-devices
      mode="report_device"
      [isAllDevicesInit]="true"
      [allDevices]="allDevices || []"
      [devices]="devices || []"
      [(selectedDevice)]="selectedDevice"
      [toolbarRightTemplate]="$any(rightTemplate)"></app-assessment-devices>
  </div>
  @if (!!selectedDevice) {
    <div class="col-12">
      <div class="cursor-pointer text-3xl font-light hover:underline" (click)="selectedDevice = null">
        <i class="fas fa-angle-left mr-2"></i>{{ selectedDevice.label }}
      </div>
    </div>
    <div class="col-10">
      <div class="grid">
        <div class="col-3">
          <microsec-card [isFull]="true" [isBordered]="true">
            <div class="flex flex-column gap-2">
              <div class="text-sm">Device Type</div>
              <div class="text-xl font-light">{{ (selectedDevice.type | constant: 'detected-device-type') || '-' }}</div>
            </div>
          </microsec-card>
        </div>
        <div class="col-3" *ngIf="!!selectedDevice.zones?.length">
          <microsec-card [isFull]="true" [isBordered]="true">
            <div class="flex flex-column gap-2">
              <div class="text-sm">Zones</div>
              <div class="flex flex-wrap gap-1">
                <ng-container *ngFor="let zone of selectedDevice.zones || []; let lastZone = last">
                  <div class="flex text-xl font-light">
                    <span [style]="{ color: zone.color }">
                      {{ zone.label }}
                    </span>
                    <span *ngIf="!lastZone">,</span>
                  </div>
                </ng-container>
              </div>
            </div>
          </microsec-card>
        </div>
        <div class="col-3">
          <microsec-card [isFull]="true" [isBordered]="true">
            <div class="flex flex-column gap-2">
              <div class="text-sm">Compliance</div>
              <div
                class="text-xl font-light"
                [ngClass]="{
                  'text-green': selectedDevice.result === ASSESSMENT_RESULTS.COMPLIANT,
                  'text-orange': selectedDevice.result === ASSESSMENT_RESULTS.PARTIALLY_COMPLIANT,
                  'text-red': selectedDevice.result === ASSESSMENT_RESULTS.NOT_COMPLIANT,
                  'text-grey': selectedDevice.result === ASSESSMENT_RESULTS.UNANSWERED || selectedDevice.result === ASSESSMENT_RESULTS.NOT_APPLICABLE
                }">
                {{ (selectedDevice.result | constant: 'assessment-result') || '-' }}
              </div>
            </div>
          </microsec-card>
        </div>
        <div class="col-3">
          <microsec-card [isFull]="true" [isBordered]="true">
            <div class="flex flex-column gap-2">
              <div class="text-sm">Compliance Score</div>
              <div
                class="text-xl font-light"
                [ngClass]="{
                  'text-green': selectedDevice.result === ASSESSMENT_RESULTS.COMPLIANT,
                  'text-orange': selectedDevice.result === ASSESSMENT_RESULTS.PARTIALLY_COMPLIANT,
                  'text-red': selectedDevice.result === ASSESSMENT_RESULTS.NOT_COMPLIANT,
                  'text-grey': selectedDevice.result === ASSESSMENT_RESULTS.UNANSWERED || selectedDevice.result === ASSESSMENT_RESULTS.NOT_APPLICABLE
                }">
                {{ ((selectedDevice.compliance_score || 0) * 100 | number: '1.0-0') + '%' }}
              </div>
            </div>
          </microsec-card>
        </div>
        <div class="col-3">
          <microsec-card [isFull]="true" [isBordered]="true">
            <div class="flex flex-column gap-2">
              <div class="text-sm">Confidence Score</div>
              <div class="flex align-items-center gap-2">
                <label class="text-xl font-light text-gradient-blue">
                  {{
                    selectedDevice.final_confidence_score === 0 || selectedDevice.final_confidence_score > 0
                      ? (selectedDevice.final_confidence_score * 100 | number: '1.0-0') + '%'
                      : '-'
                  }}
                </label>
                <img src="/assets/images/prefilling-icon.svg" alt="prefilling-icon" />
              </div>
            </div>
          </microsec-card>
        </div>
      </div>
    </div>
    <div class="col-2"></div>
    <div class="col-12">
      <app-assessment-report-question
        [isDevice]="true"
        [hideMitigations]="selectedDevice.result === ASSESSMENT_RESULTS.COMPLIANT || selectedDevice.result === ASSESSMENT_RESULTS.UNANSWERED"
        [showScoreSL]="showScoreSL"
        [sections]="selectedDevice.sections || []"></app-assessment-report-question>
    </div>
  }
</div>
